<template>
	<S2SForm title="Search Device's Rejected Events">
		<v-data-table ref="dataTable" :headers="headers" :items="rejectedEvents" :loading="status.loading" class="elevation-1" :item-class="itemRowBackground">
			<template v-slot:item.CreateDate="{ item }">
				{{ formatDate(item.CreateDate) }}
			</template>
			<template v-slot:item.retry="{ item }">
				<v-btn @click="retry(item)">
					<v-icon>
						mdi-refresh
					</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import { Utils } from "@/utils";

export default {
	name: "RejectedEvents",

	computed: {
		rejectedEvents: function() {
			return this.$store.state.devices.rejectedEvents;
		},
		status: function() {
			return this.$store.state.devices.status;
		}
	},

	data: function() {
		return {
			headers: [
				{
					text: "Id",
					value: "Id"
				},
				{
					text: "Status",
					value: "Status"
				},
				{
					text: "Amount",
					value: "Amount"
				},
				{
					text: "DeviceSerial",
					value: "DeviceSerial"
				},
				{
					text: "Created Date",
					value: "CreateDate"
				},
				{
					text: "Retry",
					value: "retry"
				}
			]
		};
	},

	created() {
		const deviceId = this.$route.params.deviceId;
		if (deviceId) {
			this.fetchRejectedEvents(deviceId);
		} else {
			this.$router.push({ name: "searchDevices" });
		}
	},

	methods: {
		itemRowBackground(item) {
			return item.Status === 200 ? "green white--text" : "";
		},
		async fetchRejectedEvents(deviceId) {
			const response = await this.$store.dispatch("devices/fetchRejectedEvents", deviceId);
		},
		async retry(item) {
			const deviceId = this.$route.params.deviceId;
			const id = item.Id;
			await this.$store.dispatch("devices/retryRejectedEvent", { event: id, deviceId: deviceId });
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		}
	}
};
</script>
